(function ($) {
  // Document ready
  $(function () {
    // Mobile menu
    $(".main-header__burger-btn").on("click", function () {
      $(".main-header__mobile-menu").toggleClass("active");
      $("body").toggleClass("overflow-hidden");
    });
    $(".main-header__mobile-menu-close").on("click", function () {
      $(".main-header__mobile-menu").removeClass("active");
      $("body").removeClass("overflow-hidden");
    });

    // Lottie player
    $(".lottie-player").each(function () {
      const src = $(this).data("src");
      lottie.loadAnimation({
        container: $(this)[0], // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: src, // the path to the animation json
      });
    });

    // On scroll events
    $(document).on("scroll", function () {
      // Sticky header
      if ($(".main-header").length) {
        let sticky = $(".main-header").height() + 200;

        if (window.pageYOffset > sticky) {
          $(".main-header").addClass("sticky");
          $(".main-header")
            .find(".navbar")
            .removeClass("navbar-dark")
            .addClass("navbar-light");
        } else {
          $(".main-header").removeClass("sticky");
          $(".main-header")
            .find(".navbar")
            .removeClass("navbar-light")
            .addClass("navbar-dark");
        }
      }
    });

    // Support hero tabs
    $(".hero-tab-btn").on("click", function (e) {
      e.preventDefault();
      const index = $(this).parent().index();
      const parent = $(this).closest(".hero-tabs-control");
      parent.find(".hero-tab-btn").removeClass("active");
      parent
        .find(".hero-tab-btn-wrap")
        .eq(index)
        .find(".hero-tab-btn")
        .addClass("active");

      const tabsParent = $(this).closest(".hero-tabs-container");
      tabsParent.find(".hero-tab").removeClass("active");
      tabsParent.find(".hero-tab").eq(index).addClass("active");

      $(".hero-slider").slick("setPosition");
    });

    // Support hero slider
    $(".hero-slider").each(function () {
      if (!$(this).hasClass("slick-initialized")) {
        $(this).slick({
          infinite: false,
          dots: true,
          arrows: true,
          slidesToShow: 1,
          appendArrows: $(this)
            .closest(".hero-slider-container")
            .find(".hero-slider-controls")[0],
          appendDots: $(this)
            .closest(".hero-slider-container")
            .find(".hero-slider-controls")[0],
          prevArrow:
            '<button type="button" class="slick-prev"><div class="arrow-left"><div></button>',
          nextArrow:
            '<button type="button" class="slick-next"><div class="arrow-right"><div></button>',
        });
        $(this).on(
          "beforeChange",
          function (event, slick, currentSlide, nextSlide) {
            const parent = $(this).closest(".hero-slider-wrap");
            const imageSlider = parent.find(".hero-image-slider");
            imageSlider.find(".hero-image-slider__slide").removeClass("active");
            imageSlider
              .find(".hero-image-slider__slide")
              .eq(nextSlide)
              .addClass("active");
          },
        );
      }
    });

    // Pricing
    $(".kids-amount__btn").on("click", function () {
      const isPlus = $(this).hasClass("plus");
      const $input = $(this)
        .closest(".kids-amount")
        .find(".kids-amount__input");
      const min = $input.attr("min");
      const max = $input.attr("max");
      let val = Number($input.val());
      if (isPlus) {
        if (val >= max) return;
        $input.val(val + 1);
      } else {
        if (val <= min) return;
        $input.val(val - 1);
      }

      calculatePrice();
    });
    $(".kids-amount__input").on("input", function () {
      const min = $(this).attr("min");
      const max = $(this).attr("max");
      let val = Number($(this).val());
      if (val >= max) {
        $(this).val(max);
      }
      if (val <= min) {
        $(this).val(min);
      }

      calculatePrice();
    });
    $("input[name=kidsTerms]").on("change", function () {
      calculatePrice();
    });

    calculatePrice();
    function calculatePrice() {
      const kids = Number($("#kidsAmount").val());
      const terms = $("input[name=kidsTerms]:checked").val();
      const termsLength = terms === "Yearly" ? 12 : terms === "Monthly" ? 1 : 1;

      const total = kids * termsLength;

      $("#summaryKids").text(kids);
      $("#summaryTerms").text(terms);
      $("#summaryTotal").text(total.toLocaleString("en"));
    }

    // Responsiveness
    resize();
    $(window).on("resize", function () {
      resize();
    });
    function resize() {
      const win = $(window); //this = window
      const width = win.outerWidth();

      if (width <= 768) {
        // Slick mobile-carousel
        $(".mobile-slick").each(function () {
          if (!$(this).hasClass("slick-initialized")) {
            $(this).slick({
              infinite: false,
              dots: false,
              arrows: false,
              slidesToShow: 1.5,
              // infinite: true,
              // slidesToShow: 3,
              // slidesToScroll: 3,
            });
          }
        });
      } else {
        // Destroy mobile-slider
        $(".mobile-slick").each(function () {
          if ($(this).hasClass("slick-initialized")) {
            $(this).slick("unslick");
          }
        });
      }
    }
  });
})(jQuery);
